<template>
  <div class="forgot-password-verify">
    <h1 class="forgot-password-verify__title">Check your mail</h1>
    <div class="forgot-password-verify__subtitle text">
      We have sent a verification code to reset your password
      <span class="forgot-password-verify__email">{{ $route.query.email }}</span>
    </div>
    <div class="forgot-password-verify__code-box">
      <VerificationCodeField
        :error="isVerificationCodeError"
        :error-txt="verificationCodeErrorText"
        @focus="resetServerError('confirmation_code')"
        @change="onChange"
        @complete="onComplete"
      />
    </div>
    <div class="forgot-password-verify__caption">
      Didn't receive the code?
    </div>
    <button class="forgot-password-verify__resend-btn" :disabled="!!getAuthNotification" @click="resendCode">
      Resend code
    </button>
    <div class="forgot-password-verify__controls">
      <ButtonBase :loading="loading" @click="resetPasswordVerify">Submit</ButtonBase>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import verifyCodeValidation from '@/mixins/validation/forms/verifyCode';
import VerificationCodeField from '@/elements/VerificationCodeField.vue';

export default {
  components: {
    VerificationCodeField,
  },
  mixins: [verifyCodeValidation],

  data() {
    return {
      verificationCode: '',
      loading: false,
    };
  },
  destroyed() {
    if (!!this.getAuthNotification)
      this.$store.commit('CLEAR_AUTH_NOTIFICATION');
  },
  computed: {
    ...mapGetters(['getAuthNotification']),
  },
  methods: {
    async resetPasswordVerify() {
      if (this.isInvalidForm) {
        this.showValidationErrors();
        return;
      }

      this.loading = true;
      await this.$store.dispatch('resetPasswordVerify', {
        email: this.$route.query.email,
        code: this.verificationCode,
      });
      this.loading = false;
    },
    async resendCode() {
      this.hideValidationErrors();
      this.resetServerError('code');

      await this.$store.dispatch('resetPassword', {
        email: this.$route.query.email,
      });
    },
    onChange(value) {
      this.verificationCode = value;
    },
    onComplete(value) {
      this.verificationCode = value;
    },
  },
};
</script>

<style lang="sass" scoped>
.forgot-password-verify
  display: flex
  flex-direction: column
  justify-content: center
  text-align: center

.forgot-password-verify__subtitle
  @include adaptive-font-size(16, 14, 12)
  @include adaptive-line-height(24, 24, 18)
  margin-top: 12px
  margin-bottom: 64px
  @include l
    margin-bottom: 44px
  @include ms
    margin-top: 8px
    margin-bottom: 40px

.forgot-password-verify__caption,
.forgot-password-verify__resend-btn
  @include adaptive-font-size(14, 14, 12)
  @include adaptive-line-height(21, 21, 18)

.forgot-password-verify__caption
  color: $white

.forgot-password-verify__resend-btn
  font-weight: 600
  color: $primary-color-blue
  margin-top: 4px
  @include ms
    margin-top: 0

.forgot-password-verify__resend-btn:active
  color: $primary-color-blue-focus

.forgot-password-verify__resend-btn[disabled]
  color: $primary-color-blue-disabled

.forgot-password-verify__email
  color: $pink-color
  font-weight: 600
  @include ms
    display: block

.forgot-password-verify__controls
  display: flex
  justify-content: center
  margin-top: 64px
  @include l
    margin-top: 44px
  @include ms
    margin-top: 40px

.forgot-password-verify__controls .btn
  padding: 12px 76px
  @include ss
    width: 100%
</style>
